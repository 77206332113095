@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
:root {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--chakra-colors-secondary);
    border: none;
    outline: none;
    box-shadow: inset 0 0 6px var(--chakra-colors-primary);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-primary);
    border-radius: 4px;
  }
}
* {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--chakra-colors-secondary);
    border: none;
    outline: none;
    box-shadow: inset 0 0 6px var(--chakra-colors-primary);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--chakra-colors-primary);
    border-radius: 5px;
  }
}
select option:hover{
  background:  var(--chakra-colors-primary) !important;
  color: white !important;
}
select:focus > option:checked { 
  background:  var(--chakra-colors-primary) !important;
  color: white !important;
}
*{
  font-family: "Cairo" !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: black;
}

// p {
//   color: #565656;
// }

.form-file-input {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      text-indent: -120px;
      margin-left: -7em;

      &::file-selector-button {
        display: inline;
      }
    }
  }
}
