.phone-input-container {
  padding: 8px 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: white !important;
  border-radius: 6px !important;
  // overflow: hidden;
}


.phone-input-container-dark {
  padding: 8px 0 !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #2d3748 !important;
  border-radius: 6px !important;
  // overflow: hidden;
}

.phone-input-input {
  width: 100% !important;
  border: 0 !important;
}
.phone-input-input-dark {
  width: 100% !important;
  border: 0 !important;
}
.phone-input-button {
  background-color: white !important;
  border: 0 !important;
  border-radius: 7px;
}
.phone-input-button-dark {
  background-color: #2d3748 !important;
  border: 0 !important;
  border-radius: 7px;
}

.cart-details-carousel .carousel .slide {
  min-width: 100%;
}

.carousel {
  .slide {
    min-width: auto ;

    img {
      width: auto ;
      height: auto ;
    }
  }
}

