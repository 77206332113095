main.page>section.hero {
  padding-top: 10px;
}

.landing-hero,
.about-us-hero,
.faqs-hero,
.pricing-hero,
.car-brands-hero,
.partner-hero,
.search-hero,
.comparator {
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // background-color: var(--chakra-colors-green-900);
  // height: 0;
}

.landing-hero {
  // background-image: url("../images/landing-hero.webp");
}

.about-us-hero {
  // background-image: url("../images/about-us-hero.webp");
  // height: 45vh;
}

.faqs-hero {
  // background-image: url("../images/faq-hero.webp");
  // height: 45vh;
}

.privacyPolicy-hero {
  // background-image: url("../images/faq-hero.webp");
  // min-height: 10vh;
}

.car-brands-hero {
  // background-image: url("../images/brands.webp");
  // height: 45vh;
}

.pricing-hero {
  // background-image: url("../images/pricing-hero.webp");
  // height: 45vh;
}

.partner-hero {
  // background-image: url("../images/partner-hero.webp");
  // height: 45vh;
}

.search-hero {
  // background-image: none !important;
  // height: 50vh;
  // background-color: #fff;
}

.comparator-hero {
  // background-image: url("../images/stats.webp");
  // height: 45vh;
}

.gradient-bg {
  background-image: url("../images/faq-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.pattern-bg {
  background-image: url("../images/bg-pattern.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.colored-pattern-bg {
  background-image: url("../images/pattern-colored.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.blobs-bg {
  background-image: url("../images/blobs-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-radial-gradient {
  position: absolute;
  bottom: -10%;
  left: 0;
  right: 0;
  height: 61%;
  background: radial-gradient(circle,
      rgba(25, 135, 84, 0.2164216028) -95%,
      rgba(255, 255, 255, 0.0623599782) 40%);
  background-position: bottom;
  background-size: contain;
}

.bottom-tab {
  position: fixed;
  width: 100%;
  bottom: 0px;
  background-color: white;
  font-size: 13px;
  padding: 3px;
  -webkit-box-shadow: 0px -7px 81px -21px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px -7px 81px -21px rgba(0, 0, 0, 0.6);
  box-shadow: 0px -7px 81px -21px rgba(0, 0, 0, 0.6);
  border-style: solid;
  border-width: 0.8px 0px;
  left: 0;
  z-index: 100;
}

.bottom-tab a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.bottom-tab a svg {
  height: 25px;

}

.nav-header {
  position: fixed;
  background-color: #fff;
  width: 100%;
  left: 0;
  padding: 15px 10px;
  -webkit-box-shadow: 0px -7px 81px -21px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px -7px 81px -21px rgba(0, 0, 0, 0.6);
  box-shadow: 0px -7px 81px -21px rgba(0, 0, 0, 0.6);
  border-style: solid;
  border-width: 0.9px 0px;
  left: 0;
  z-index: 100;
  top:0
}

// ======================
//mediam screen
//====================
@media (min-width: 768px) {
  .search-hero {
    background-image: url("../images/landing-hero.webp");
    height: 50vh;
  }

  main.page>section.hero {
    padding-top: 96px;
  }

  .landing-hero,
  .about-us-hero,
  .faqs-hero,
  .pricing-hero,
  .car-brands-hero,
  .partner-hero,
  .search-hero,
  .comparator {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: var(--chakra-colors-green-900);
    height: 100%;
  }
  .landing-hero {
    background-image: url("../images/landing-hero.webp");
  }
  
  .about-us-hero {
    background-image: url("../images/about-us-hero.webp");
    height: 45vh;
  }
  
  .faqs-hero {
    background-image: url("../images/faq-hero.webp");
    height: 45vh;
  }
  
  .privacyPolicy-hero {
    background-image: url("../images/faq-hero.webp");
    min-height: 10vh;
  }
  
  .car-brands-hero {
    background-image: url("../images/brands.webp");
    height: 45vh;
  }
  
  .pricing-hero {
    background-image: url("../images/pricing-hero.webp");
    height: 45vh;
  }
  
  .partner-hero {
    background-image: url("../images/partner-hero.webp");
    height: 45vh;
  }
  .comparator-hero {
    background-image: url("../images/stats.webp");
    height: 45vh;
  }
}